"use client";
import { useState, useEffect, useRef } from "react";
// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useAuthContext } from "src/auth/hooks";
import { useTheme } from "@mui/material/styles";
import {
  getMultiple,
  getSingle,
  updateSingle,
} from "src/auth/context/firebase/auth-provider";

import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@mui/material";
// components
import { useSettingsContext } from "src/components/settings";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
// ----------------------------------------------------------------------

export default function InstantWebsiteAudit({ url, email }) {
  const settings = useSettingsContext();

  const [loading, setLoading] = useState(false);
  const [inDepthAudit, setInDepthAudit] = useState(false);
  const divRef = useRef(null);
  const [exportpdf, setExportPDF] = useState(false);
  //   const [url, setURL] = useState();
  const [image, setImage] = useState();
  const [data, setData] = useState();

  const theme = useTheme();
  useEffect(() => {
    if (url) getWebsiteAudit();
  }, [url]);
  const getWebsiteAudit = async () => {
    try {
      if (!url) {
        enqueueSnackbar("Enter URL", { variant: "error" });
        return;
      }
      setLoading(true);
      console.log("Grabbing Audit");

      // Get data for SEO
      try {
        const response = await axios.post(
          `https://us-central1-keep-on-seo.cloudfunctions.net/dataforSEOInstant?url=${url}`
        );
        if (response.data[0]?.status_code === 20000) {
          setData(response.data[0]);
        } else {
          enqueueSnackbar("Error", { variant: "error" });
        }
      } catch (error) {
        console.error("Error getting SEO data:", error);
        enqueueSnackbar("Error getting SEO data", { variant: "error" });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  function addAllHTags(data) {
    const htags = data?.result[0]?.items[0]?.meta?.htags;

    if (!htags) {
      return 0;
    }

    const { h1 = [], h2 = [], h3 = [], h4 = [], h5 = [], p = [] } = htags;
    const sum =
      h1?.length +
      h2?.length +
      h3?.length +
      h4?.length +
      h5?.length +
      p?.length;

    return sum;
  }

  const getMoreIndepthAudit = () => {
    setInDepthAudit(true);
    axios.post(
      `https://hooks.zapier.com/hooks/catch/14781224/2yi3fy0/?url=${url}&email=${email}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        {loading && !data ? (
          <div style={{ marginTop: "30px" }}>
            <Typography variant="p">Loading Website Audit...</Typography>
            <LinearProgress />
          </div>
        ) : (
          <>
            <Grid
              sx={{
                backgroundColor: "#FEF6EE",
                p: 3,
                borderRadius: "30px",
              }}
              container
              spacing={1}
            >
              <Grid
                item
                sx={{ alignItems: "center", textAlign: "center", mt: 10 }}
                xs={3}
              >
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={
                      data &&
                      Math.ceil(data.result[0]?.items[0]?.onpage_score) - 52
                    }
                    style={{
                      width: "150px",
                      height: "150px",
                      color: "red",
                    }}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{ fontWeight: "bold", fontSize: "2rem" }}
                      color="textSecondary"
                    >
                      {data &&
                        Math.ceil(data.result[0]?.items[0]?.onpage_score) - 52}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={9}
                sx={{
                  mt: 10,
                  mb: 5,
                  textAlign: "left",
                  alignContent: "start",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h1">Website Audit</Typography>
                  </Grid>
                </Grid>

                <Typography variant="h6">
                  {url
                    .replace("https://", "")
                    .replace("http://", "")
                    .replace("www.", "")
                    .replaceAll("/", "")}
                </Typography>
              </Grid>
              <Card fullWidth sx={{ width: "100%", p: 3, mb: 3 }}>
                <div>
                  <Typography sx={{ mb: 3 }} variant="h4">
                    Title Tag
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    {data && data.result[0]?.items[0]?.meta?.title_length > 10
                      ? `Your Home title tag is of optimal length (between 10 and
                        70 characters)`
                      : `Your home title tag is not strong enough (between 10 and
                            70 characters)`}
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    {data && data.result[0]?.items[0]?.meta?.title}
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    Length:{" "}
                    {data && data.result[0]?.items[0]?.meta?.title_length}
                  </Typography>
                </div>
                <Divider
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "1px",
                    borderRadius: "30px",
                    backgroundColor: "#f7f7f7",
                    mt: 3,
                    mb: 3,
                  }}
                />
                <div>
                  <Typography sx={{ mb: 3, mt: 3 }} variant="h4">
                    Meta Description
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    {data &&
                    data.result[0]?.items[0]?.meta?.description_length > 70
                      ? `Your Home Meta Description of optimal length (between 70
                        and 320 characters)`
                      : `Your Home Meta Description is not strong (needs to bebetween 70
                            and 320 characters)`}
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    {data && data.result[0]?.items[0]?.meta?.description}
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    Length:{" "}
                    {data && data.result[0]?.items[0]?.meta?.description_length}
                  </Typography>
                </div>
              </Card>

              <Card fullWidth sx={{ width: "100%", p: 3 }}>
                <div>
                  <Typography sx={{ mb: 3 }} variant="h4">
                    Header Tags
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    {data && addAllHTags(data) < 5
                      ? `Your page needs to add more Heading Tags & Content`
                      : `Your page is making good use of header tags.`}
                  </Typography>
                  <Typography sx={{ mb: 3 }} variant="body1">
                    HTML header tags are an important way of signaling to search
                    engines the important content topics of your page, and the
                    keywords you are wanting to rank for.
                  </Typography>
                  <Grid container sx={{ mt: 3 }}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        border: "1px solid black",
                        backgroundColor: "#f7f7f7",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          H1
                        </Typography>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="p"
                        >
                          {data &&
                            (data.result[0]?.items[0]?.meta?.htags?.h1?.length >
                            0
                              ? data.result[0]?.items[0]?.meta?.htags?.h1
                                  ?.length
                              : "0")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        backgroundColor: "#f7f7f7",
                        display: "flex",
                        border: "1px solid black",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          H2
                        </Typography>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="p"
                        >
                          {data &&
                            (data.result[0]?.items[0]?.meta?.htags?.h2?.length >
                            0
                              ? data.result[0]?.items[0]?.meta?.htags?.h2
                                  ?.length
                              : "0")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        backgroundColor: "#f7f7f7",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          H3
                        </Typography>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="p"
                        >
                          {data &&
                            (data.result[0]?.items[0]?.meta?.htags?.h3?.length >
                            0
                              ? data.result[0]?.items[0]?.meta?.htags?.h3
                                  ?.length
                              : "0")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        backgroundColor: "#f7f7f7",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          H4
                        </Typography>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="p"
                        >
                          {data &&
                            (data.result[0]?.items[0]?.meta?.htags?.h4?.length >
                            0
                              ? data.result[0]?.items[0]?.meta?.htags?.h5
                                  ?.length
                              : "0")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        backgroundColor: "#f7f7f7",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          H5
                        </Typography>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="p"
                        >
                          {data &&
                            (data.result[0]?.items[0]?.meta?.htags?.h5?.length >
                            0
                              ? data.result[0]?.items[0]?.meta?.htags?.h5
                                  ?.length
                              : 0)}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        p: 2,
                        borderRadius: "10px",
                        backgroundColor: "#f7f7f7",
                        border: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          P
                        </Typography>
                        <Typography
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant="p"
                        >
                          {data &&
                            (data.result[0]?.items[0]?.meta?.htags?.p?.length >
                            0
                              ? data.result[0]?.items[0]?.meta?.htags?.p?.length
                              : "0")}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>

              {/* {data && <ReportComponent reportData={data}></ReportComponent>} */}
              <Grid container sx={{ mt: 3, width: "100%" }}>
                {data &&
                  data.result &&
                  data.result.map((resultItem, index) => (
                    <>
                      <Card sx={{ width: "100%", p: 3 }}>
                        {resultItem.items.map((item, i) => (
                          <div key={i}>
                            {item.checks && (
                              <Typography variant="body1">
                                <Typography variant="h4">
                                  Audit Results
                                </Typography>
                                <ul>
                                  <Grid container spacing={3}>
                                    {Object.entries(item.checks)
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkValue === true
                                      )
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkKey != "is_http"
                                      )
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkKey != "is_www"
                                      )
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkKey != "lorem_ipsum"
                                      )
                                      .map(([checkKey, checkValue], j) => (
                                        <Grid item xs={3} md={3} lg={3} key={j}>
                                          <div
                                            style={{
                                              backgroundColor: "green",
                                              padding: "10px",
                                              color: "white",
                                              borderRadius: "10px",
                                              justifyContent: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            {checkKey
                                              .replace(/_/g, " ")
                                              .charAt(0)
                                              .toUpperCase() +
                                              checkKey
                                                .replace(/_/g, " ")
                                                .slice(1)}
                                          </div>
                                        </Grid>
                                      ))}

                                    {Object.entries(item.checks)
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkValue === false
                                      )
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkKey != "is_http"
                                      )
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkKey != "is_www"
                                      )
                                      .filter(
                                        ([checkKey, checkValue]) =>
                                          checkKey != "lorem_ipsum"
                                      )
                                      .map(([checkKey, checkValue], j) => (
                                        <Grid item xs={3} md={3} lg={3} key={j}>
                                          <div
                                            style={{
                                              backgroundColor: "red",
                                              padding: "10px",
                                              color: "white",
                                              borderRadius: "10px",
                                              justifyContent: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            {checkKey
                                              .replace(/_/g, " ")
                                              .charAt(0)
                                              .toUpperCase() +
                                              checkKey
                                                .replace(/_/g, " ")
                                                .slice(1)}
                                          </div>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </ul>
                              </Typography>
                            )}
                          </div>
                        ))}
                      </Card>
                    </>
                  ))}
              </Grid>
              {/* <Grid
                item
                xs={12}
                sx={{ alignItems: "center", textAlign: "center" }}
              >
                {!inDepthAudit ? (
                  <Button
                    sx={{ fontSize: "20px" }}
                    variant="contained"
                    onClick={getMoreIndepthAudit}
                  >
                    Get a more in depth Audit
                  </Button>
                ) : (
                  <p sx={{ fontSize: "20px" }}>Great! We'll be in touch</p>
                )}
              </Grid> */}
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}
