// ----------------------------------------------------------------------

import { useEffect, useState } from "react";
import InstantWebsiteAudit from "../demo/instant-website-audit";

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    website: params.get("website") || "",
    email: params.get("email") || "",
  };
}

export default function AuditView() {
  const [website, setWebsite] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    const { website, email } = getQueryParams();
    setWebsite(website);
    setEmail(email);
  }, []);
  return <>{website && <InstantWebsiteAudit url={website} email={email} />}</>;
}
